import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Card = ({ children, fluidImage, name, title, email }) => (
  <div className='card'>
    {fluidImage && (
      <GatsbyImage image={fluidImage} className='card__image' alt={name} />
    )}
    <h2 className='heading card__name'>{name}</h2>
    <h3 className='heading card__title'>{title}</h3>
    {email && (
      <a className='card__email link' href={`mailto:${email}`}>
        {email}
      </a>
    )}
    {children}
  </div>
)

export default Card
