import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/Layout'
import Card from 'components/Card'

const OmOss = ({ data }) => (
  <Layout pageTitle='Om oss'>
    <oma-grid-row>
      <div className='section'>
        <h1 className='heading page__heading'>Om oss</h1>
        <div className='cards'>
          <Card
            name='Mikael Lektonius'
            title='Leg. Naprapat'
            fluidImage={data.mikael.childImageSharp.gatsbyImageData}
            email='micke@naprapatakuten.com'
          >
            <p className='card__text'>
              Mikael Lektonius driver Naprapatakuten och är leg. naprapat sedan
              2001. Har gått många olika vidareutbildningar inom bl.a.
              akupunktur, dry needling, stötvåg och idrottsmedicin. Har även
              gått grundkurserna inom OMI klinisk ortopedisk medicin. Han
              försöker gå flera kurser per år och medverkar även på Svenska
              naprapatförbundets årsmöten för att hålla sig ajour med vad som
              händer inom yrket. Även om de flesta patienter kommer med
              ryggbesvär så kan han även hjälpa dig med allt från
              tennisarmbåge,stukade fötter, hälsporre, axlar och händer. Jobbar
              mycket med massage, manipulation, akupunktur och stötvåg men
              anpassar sig så klart efter vad patienten vill och behöver.
            </p>
          </Card>
          <Card
            name='Elisabeth Widén (fd Blomkvist)'
            title='Leg. Naprapat'
            fluidImage={data.elisabeth.childImageSharp.gatsbyImageData}
            email='elisabeth@naprapatakuten.com'
          >
            <p className='card__text'>
              Elisabeth arbetar 3-4 dagar i veckan på NaprapatAkuten sedan
              November 2014. Hon driver även Friskvårdsföretaget Anatomic Work
              tillsammans med en Naprapatkollega i Uppsala. Elisabeth har
              tidigare jobbat på Totalhälsan i Uppsala och
              Idrottskadespecialisterna i Stockholm. Hon är sedan 2006 utbildad
              danspedagog och koreograf vid Gotlands dansutbildningar och har
              delvis studerat vid Dans och Cirkushögskolan i Stockholm. Innan
              arbetade hon med dans på heltid före studierna på
              Naprapathögskolan och har länge arbetat med rörelseanalys.
              Elisabeth har läst psykologi på Uppsala universitet och har ett
              stort intresse för hur vår mentala och fysiska hälsa samverkar.
            </p>
          </Card>
          <Card
            name='Birgitta Liljeqvist'
            title='Massage-terapeut'
            fluidImage={data.birgitta.childImageSharp.gatsbyImageData}
          >
            <p className='card__text'>
              Birgitta är utbildad massage-terapeut inom Svensk klassisk
              massage. Svensk klassisk massage är en djupgående massage som
              löser upp spänningar i hela kroppen och ger en behaglig och skön
              avslappning. Regelbunden massage kan motverka trötthet, smärta i
              kroppens olika muskler. Dessutom bidrar den till att du känner dig
              lugnare, mjukare och mer rörlig.
            </p>
            <a
              className='link link--button'
              href='https://www.bokadirekt.se/places/biwo-friskv%C3%A5rd-2721'
            >
              Boka tid med Birgitta
            </a>
          </Card>
        </div>
      </div>
    </oma-grid-row>
  </Layout>
)

export const portrait = graphql`
  fragment portrait on File {
    childImageSharp {
      gatsbyImageData(width: 550, height: 750, quality: 85, layout: CONSTRAINED)
    }
  }
`

export const query = graphql`
  {
    mikael: file(relativePath: { eq: "mikael.jpg" }) {
      ...portrait
    }
    elisabeth: file(relativePath: { eq: "elisabeth.jpg" }) {
      ...portrait
    }
    birgitta: file(relativePath: { eq: "birgitta.jpg" }) {
      ...portrait
    }
  }
`

export default OmOss
